import { useParams } from 'react-router-dom';
import { useAd } from '../data/hooks';
import AdDisplay from '../components/adDisplay';
import Button from '@mui/material/Button';
import ProfileTargeting from '../components/profileTargeting';

const Ad = () => {
  const { adID } = useParams();
  const {ad, handleTemporaryChange, handleTemporaryTargetingChange, changesMade, saveChanges} = useAd({adID});

  return (
    <>
    	<AdDisplay ad={ad} handleChange={handleTemporaryChange} />

      <ProfileTargeting targeting={ad && ad.targeting} handleChange={handleTemporaryTargetingChange} />

      <Button
        onClick={saveChanges}
        disabled={!changesMade}>
        Save changes
      </Button>
    </>
  );
}

export default Ad;