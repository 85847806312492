import { useNavigate } from 'react-router-dom';
import { makeNewAd } from '../utils/functions/firestore';
import Button from '@mui/material/Button';

const Ad = () => {
  const navigate = useNavigate();

  const newAd =  async () => {
    const newID = await makeNewAd({type: 'post'});

    console.log('newID', newID);

    navigate(`/ads/${newID}`);
  }

  return (
    <Button onClick={newAd}>Make new Ad</Button>
  );
}

export default Ad;