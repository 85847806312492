import { Model } from "./base.js";

export class Ad extends Model {
  get defaults() {
    return {
      type: '',
      CPC: -1,
      goal: '',
      impressions: 0,
      clicks: 0,
      publisher: null,
      creative: null,
      createdAt: (new Date()),
      startAt: (new Date()),
      endAt: (new Date()),
    };
  }

  get CTR () {
    if (this.impressions) {
      return this.clicks / this.impressions;
    }

    return -1;
  }
}
