import {useState} from 'react';
import {auth} from '../utils/firebase';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';

const LogIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { from } = location.state || { from: { pathname: "/" } };

  const handleLogin = (e) => {
    e.preventDefault();

    signInWithEmailAndPassword(auth, email.trim(), password.trim())
      .then(() => {
        navigate(from);
      })
      .catch((error) => {
        console.error('error sending email link', error);
        setError(String(error));
      });
  };

  const onEmailChange = ({target}) => {
    setEmail(target.value);
    setError('');
  };

  const onPasswordChange = ({target}) => {
    setPassword(target.value);
    setError('');
  };

  return (
    <Box p={5}>
      <Typography>Please Log In</Typography>

      <form onSubmit={handleLogin} style={{maxWidth: '100%', width: '600px'}}>
        <Box my={1}>
          <FormControl fullWidth>
            <InputLabel htmlFor="emailinput">Email</InputLabel>
            <Input
              value={email}
              onChange={onEmailChange}
            />
          </FormControl>
        </Box>

        <Box my={1}>
          <FormControl fullWidth>
            <InputLabel htmlFor="emailinput">Password</InputLabel>
            <Input
              type="password"
              value={password}
              onChange={onPasswordChange}
            />
          </FormControl>
        </Box>

        {error && (
          <Box my={2}>
            <Typography style={{color: 'white'}} align="center">
              Error: {error}
            </Typography>
          </Box>
        )}

        <Box my={2}>
          <Button
            fullWidth
            type="submit"
            variant="contained">
            Login
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default LogIn;
