import { auth } from '../utils/firebase';
import { useEffect, useState } from 'react';
import { UserContext } from './context';

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [initialised, setInitialised] = useState(false);

  useEffect(() => {
    return auth
      .onAuthStateChanged(function(user) {
        setInitialised(true);
        if (user) {
          setUser(user);
        } else {
          setUser(null);
        }
      });
  }, []);

  return (
    <UserContext.Provider value={{user, initialised}}>
      {children}
    </UserContext.Provider>
  );
}