import {useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import {useUserWithUID} from '../data/hooks';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CheckIcon from '@mui/icons-material/Check';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {codeToColorHex} from '../utils/functions/misc';

const UserCourse = ({uc}) => {
  const {color, course, fullcourse, options, confidences} = uc;
  return (
    <>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: codeToColorHex(color)}}>
           <CheckIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`Course ${fullcourse ? `${fullcourse.level} ${fullcourse.subject} ${fullcourse.board}` : course}`}
          secondary={
            <>
              <Typography
                color="text.primary"
              >
                <strong>{(options && options.length) || '0'}</strong> modules selected 
              </Typography>

              <Typography
                color="text.primary"
              >
                <strong>{(confidences && Object.keys(confidences).length) || '0'}</strong> topics rated 
              </Typography>
            </>
          }
        />
      </ListItem>
    </>
  );
}

const User = () => {
  const { uid } = useParams();
  const user = useUserWithUID(uid, false);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (!user) {
    return null;
  }

  const {
    name,
    email,
    streamName,
    socialPostCount,
    profileBlurb,
    profileHeadline,
    blueTick,
    profilepicture,
    year,
    school,
    os,
    osVersion,
    onVersion,
    studydays,
    recommendationrate,
    disabled,
  } = user;

  return (
    <div style={{margin: 'auto', maxWidth: 800}}>
      <Card>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            User
          </Typography>

          <Typography variant="h5" component="div">
             {name}
          </Typography>

          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {email}
          </Typography>

          <Typography sx={{ mb: 1.5 }}>
            Disabled? <strong>{disabled ? 'DISABLED' : 'no'}</strong>
          </Typography>

          <Avatar alt="Remy Sharp" src={profilepicture}  sx={{ width: 150, height: 150 }} />
        </CardContent>
      </Card>

      <Accordion expanded={expanded === 'tech'} onChange={handleChange('tech')}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Tech stuff</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Tech stuff
          </Typography>

          <Typography sx={{ mb: 1.5 }}>
            Last used platform: <strong>{os || 'unknown'} ({osVersion || 'unknown version'})</strong>
          </Typography>

          <Typography sx={{ mb: 1.5 }}>
            Last used app version: <strong>{onVersion || 'unknown'}</strong>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'school'} onChange={handleChange('school')}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>School</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            School stuff
          </Typography>

          <Typography sx={{ mb: 1.5 }}>
            School: <strong>{school && school.name} ({school ? school.id : 'no school'}}</strong>
          </Typography>

          <Typography sx={{ mb: 1.5 }}>
            School year: <strong>{year}</strong>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'meta'} onChange={handleChange('meta')}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Metadata</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Subjects ({user.userCourses.length && user.userCourses.length})
          </Typography>

          {user.userCourses && user.userCourses.length && (
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {user.userCourses.map((uc) => (
                <UserCourse
                  key={uc.course}
                  uc={uc}
                />
              ))}
            </List>
          )}
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === 'study'} onChange={handleChange('study')}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Study settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Study settings
          </Typography>

          <Typography sx={{ mb: 1.5 }}>
            Study days: <strong>{!studydays || !Object.keys(studydays).length ? 'none' : Object.keys(studydays).map(skey => studydays[skey] ? `${skey}, ` : '').join('')}</strong>
          </Typography>

          <Typography sx={{ mb: 1.5 }}>
            Tasks per day: <strong>{recommendationrate}</strong>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'courses'} onChange={handleChange('courses')}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Subjects</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Subjects ({user.userCourses.length && user.userCourses.length})
          </Typography>

          {user.userCourses && user.userCourses.length && (
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {user.userCourses.map((uc) => (
                <UserCourse
                  key={uc.course}
                  uc={uc}
                />
              ))}
            </List>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'social'} onChange={handleChange('social')}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Social</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Social stuff
          </Typography>

          <Typography sx={{ mb: 1.5 }}>
            Blue tick: <strong>{blueTick ? 'TRUE' : 'false'}</strong>
          </Typography>

          <Typography sx={{ mb: 1.5 }}>
            Social name: <strong>{streamName}</strong>
          </Typography>

          <Typography sx={{ mb: 1.5 }}>
            Social Blurb: <strong>{profileBlurb}</strong>
          </Typography>

          <Typography sx={{ mb: 1.5 }}>
            Social Headline: <strong>{profileHeadline}</strong>
          </Typography>

          <Typography sx={{ mb: 1.5 }}>
            Post count: <strong>{socialPostCount}</strong>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={true}>
        <AccordionSummary
            aria-controls="panel4bh-content"
            id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Actions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button variant="contained">
            Ban user
          </Button>
          <br/>
          <Button variant="contained">
            Disable user
          </Button>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default User;
