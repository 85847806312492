import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';

const TargetingSection = ({title, children}) => {
  return (
    <>
      <Paper variant="outlined" sx={{p: 2, m: 1}}>
        <Typography variant="h6" element="h3" gutterBottom>{title}</Typography>

        {children}
      </Paper>
    </>
  );
}

const SocialTargeting = ({hasSocialEnabled, hasBlueTick, handleChange, disabled}) => {
  const handleSocialEnabled = (event, newValue) => {
    handleChange('hasSocialEnabled', newValue);
    if (!newValue) {
      handleChange('hasBlueTick', false);
    }
  };
  const handleBlueTick = (event, newValue) => {
    handleChange('hasBlueTick', newValue);
    if (newValue) {
      handleChange('hasSocialEnabled', true);
    }
  };


  return (
    <TargetingSection title="Social attributes">
      <FormGroup>
        <FormControlLabel
          disabled={disabled}
          control={<Checkbox checked={hasSocialEnabled} />}
          label="Has social enabled"
          onChange={handleSocialEnabled}
        />
      </FormGroup>

      <FormGroup>
        <FormControlLabel
          disabled={disabled}
          control={<Checkbox checked={hasBlueTick} />}
          label="Has blue tick"
          onChange={handleBlueTick}
        />
      </FormGroup>
    </TargetingSection>
  );
}

const OSType = ({os, includeUnknown, handleChange, disabled}) => {
  const handlePlatform = (event, newValue) => {
    handleChange('os', newValue);
  };
  const handleIncludeUnknown = (event, newValue) => {
    handleChange('includeUnknown', newValue);
  };

  return (
    <TargetingSection title="Operating system">
      <ToggleButtonGroup
        value={os}
        exclusive
        onChange={handlePlatform}
        aria-label="text alignment"
        disabled={disabled}
      >
        <ToggleButton value="both" aria-label="both">
          Both
        </ToggleButton>

        <ToggleButton value="ios" aria-label="iOS only">
          iOS only
        </ToggleButton>

        <ToggleButton value="android" aria-label="android">
          Android only
        </ToggleButton>

      </ToggleButtonGroup>

      <FormGroup>
        <FormControlLabel
          disabled={disabled}
          control={<Checkbox checked={includeUnknown} />}
          label="Include unknown platforms"
          onChange={handleIncludeUnknown}
        />
      </FormGroup>
    </TargetingSection>
  );
}

const Version = ({versionMax, versionMaxInclusive, versionMin, versionMinInclusive, versionExact, handleChange, disabled}) => {
  const handleMax = (evt, val) =>
    handleChange('versionMax', val);
  const toggleMaxIncl = (evt, val) =>
    handleChange('versionMaxInclusive', !versionMaxInclusive);
  const handleMin = (evt, val) =>
    handleChange('versionMin', val);
  const handleExact = (evt, val) =>
    handleChange('versionExact', val);
  const toggleMinIncl = (evt, val) =>
    handleChange('versionMinInclusive', !versionMinInclusive);

  return (
    <TargetingSection title="App version">
      <TextField
        label={`Max version (${versionMaxInclusive ? 'inclusive' : 'non-inclusive'})`}
        onChange={handleMax}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-label="toggle max version inclusivity"
                onClick={toggleMaxIncl}
                edge="start"
              >
                {versionMaxInclusive ? <Typography>{'<='}</Typography> : <Typography>{'<'}</Typography>}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        label={`Min version (${versionMinInclusive ? 'inclusive' : 'non-inclusive'})`}
        onChange={handleMin}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-label="toggle max version inclusivity"
                onClick={toggleMinIncl}
                edge="start"
              >
                {versionMinInclusive ? <Typography>{'>='}</Typography> : <Typography>{'>'}</Typography>}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        label="Exact version"
        onChange={handleExact}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography>{'=='}</Typography>
            </InputAdornment>
          ),
        }}
      />
    </TargetingSection>
  );
}

const ProfileTargeting = ({targeting, handleChange, disabled}) => {
  const {
    os = 'both',
    includeUnknown = true,
    versionMin = null,
    versionMinInclusive = true,
    versionMax = null,
    versionMaxInclusive = true,
    versionExact,
    hasSocialEnabled = false,
    hasBlueTick = false,
  } = targeting || {};

  return (
    <>
      <Typography variant="h5" element="h2" gutterBottom>User Targeting</Typography>

      <OSType os={os} includeUnknown={includeUnknown} handleChange={handleChange} disabled={disabled} />

      <Version
        versionMax={versionMax}
        versionMaxInclusive={versionMaxInclusive}
        versionMin={versionMin}
        versionMinInclusive={versionMinInclusive}
        handleChange={handleChange}
        disabled={disabled}
      />

      <SocialTargeting
        hasSocialEnabled={hasSocialEnabled}
        hasBlueTick={hasBlueTick}
        handleChange={handleChange}
        disabled={disabled}
      />
    </>
  );
}

export default ProfileTargeting;