import PostAd from './postAd';

const AdDisplay = ({ad, handleChange}) => {
	if (!ad) {
		return null;
	}

	if (ad.type === 'post') {
		return <PostAd ad={ad} handleChange={handleChange} />;
	}

	return null;
}

export default AdDisplay;