import {useState, useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import { functions } from '../utils/firebase';
import { httpsCallable } from "firebase/functions";
import UserPanel from '../components/UserPanel';
import {useUserWithUID} from '../data/hooks';

const getUID = httpsCallable(functions, 'admin-getUID');
const getUserData = httpsCallable(functions, 'admin-getUserData');

const UserSearch = ({onSelect}) => {
  const [error, setError] = useState(null);
  const [text, setText] = useState(null);

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const search = async () => {
    const resp = await getUID({email: text});

    onSelect(resp && resp.data && resp.data.uid);
  }

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
    >
      <TextField
        error={!!error}
        id="standard-error-helper-text"
        label="Email"
        defaultValue=""
        value={text}
        helperText={error ? error.message : "Type the user's email address."}
        variant="standard"
        onChange={handleChange}
      />

      <Button variant="contained" endIcon={<SearchIcon />} onClick={search}>
        Search
      </Button>
    </Box>
  );
}

const UserDetails = ({uid}) => {
  const user = useUserWithUID(uid);

  if (!user) {
    return null;
  }

  return (
    <UserPanel
      uid={uid}
      email={user.email}
      name={user.name}
      disabled={user.disabled}
    />
  );
}

const Users = () => {
  const [uid, setUID] = useState(null);

  const onSelectUser = useCallback((uid) => {
    setUID(uid);
  }, []);

  return (
    <Box>
      <UserSearch onSelect={onSelectUser} />

      <UserDetails uid={uid} />
    </Box>
  );
};

export default Users; 
