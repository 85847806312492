import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const ButtonLink = ({children, ...props}) => {
  return (
    <Button component={Link} {...props}>
      {children}
    </Button>
  );
}

export default ButtonLink;