import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import DateTimePicker from '@mui/lab/DateTimePicker';

const PostAd = ({ad, handleChange}) => {
  if (!ad) {
    return null;
  }

	return (
		<Box
      sx={{
        '& > :not(style)': { m: 1 },
      }}>
      <Typography>Ad {ad.id}</Typography>  
      <Typography>Ad type: {ad.type}</Typography>

      <DateTimePicker
        renderInput={(props) => <TextField {...props} />}
        label="Created at"
        value={ad.createdAt || (new Date())}
        onChange={(value) => handleChange('createdAt', value)}
      />

      <DateTimePicker
        renderInput={(props) => <TextField {...props} />}
        label="Start campaign at"
        value={ad.startAt || (new Date())}
        onChange={(value) => handleChange('createdAt', value)}
      />

      <DateTimePicker
        renderInput={(props) => <TextField {...props} />}
        label="End campaign at"
        value={ad.endAt || (new Date())}
        onChange={(value) => handleChange('createdAt', value)}
      />
    </Box>
	);
}

export default PostAd;