import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ButtonLink from './ButtonLink';

export default function UserPanel({name, email, uid, disabled}) {
  return (
    <Card sx={{ minWidth: 275, maxWidth: 600 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          User
        </Typography>
        <Typography variant="h5" component="div">
          {name}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {email}
        </Typography>
      </CardContent>
      <CardActions>
        <ButtonLink to={`/users/${uid}`} size="small">Go to user</ButtonLink>
      </CardActions>
    </Card>
  );
}