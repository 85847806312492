const CODES_TO_COLORS = {
  '01': '#68B7FC',
  '02': '#f9d35a',
  '03':  '#FA6565',
  '04': '#15BF9A',
  '05': '#8576F6',
  '06': '#FA8C65',
  '07': '#FA65A4',
  '08': '#96D874',
  '09': '#156CBF',
  '10': '#15A3BF',
  '11': '#bdbdbd',
  '12': '#181818',
};

export const codeToColorHex = (code) => {
	return CODES_TO_COLORS[code] || CODES_TO_COLORS['01'];
} 
