import { firestore } from '../firebase';
import { Ad } from '../../data/models/ads';
import { collection, addDoc } from 'firebase/firestore';

export const makeNewAd = async ({type}) => {
  const newAd = new Ad({type});

  const docRef = await addDoc(
    collection(firestore, 'ads'),
    newAd.data(),
  );

  return docRef.id;
}