import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyDCzApjZi6aWDmZKDikxkuMMcsQtd0qZnU',
  authDomain: 'adapt-38a7d.firebaseapp.com',
  databaseURL: 'https://adapt-38a7d.firebaseio.com',
  projectId: 'adapt-38a7d',
  storageBucket: 'adapt-38a7d.appspot.com',
  messagingSenderId: '176844607775',
  appId: '1:176844607775:web:5f909106368d432077e02a',
  measurementId: 'G-PDT2K6R0EC',
};

try {
  initializeApp(firebaseConfig);
} catch (err) {
  if (!/already exists/.test(err.message)) {
    console.error('Firebase initialization error', err.stack);
  }
}

const firestore = getFirestore();
const auth = getAuth();
const functions = getFunctions(undefined, 'europe-west2');
// 
// connectFirestoreEmulator(firestore, 'localhost', 8080);
// connectAuthEmulator(auth, "http://localhost:9099");
// connectFunctionsEmulator(functions, "localhost", 5001);

export {firestore, auth, functions};