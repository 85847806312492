import { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { UserContext } from './context';
import { firestore } from '../utils/firebase';
import { doc, getDocs, collectionGroup, orderBy, query, limit, getDocFromServer, updateDoc } from 'firebase/firestore';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import { functions } from '../utils/firebase';
import { httpsCallable } from "firebase/functions";

// const getUID = httpsCallable(functions, 'admin-getUID');
const getUserData = httpsCallable(functions, 'admin-getUserData');

export const useUser = () => {
  return useContext(UserContext);
}

export const useUserWithUID = (uid, minimum=true) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const doGet = async () => {
      const resp = await getUserData({uid, minimum: minimum});

      console.log('resp', resp && resp.data);

      setUser(resp && resp.data);
    }

    if (uid) {
      doGet();
    } else {
      setUser(null);
    }
  }, [uid]);

  if (!user) {
    return null;
  }

  return user;
}

export const useReports = ({variant, limit: tLimit}) => {
  const [rows, setRows] = useState([])

  useEffect(() => {
    const doGet = async () => {
      const que = query(
        collectionGroup('reports'),
        orderBy('name'),
        limit(tLimit),
      );

      const snap = await getDocs(que);
      
      setRows(snap.docs.map(doc => doc.data()));
    }

    doGet();
  }, [tLimit]);

  return rows;
}

const noUpdateAds = ['id', 'createdAt'];
export const useAd = ({adID}) => {
  const [serverAd, setServerAd] = useState(null)
  const [ad, setAd] = useState(null)

  useEffect(() => {
    const doGet = async () => {
      const docRef = doc(firestore, 'ads', adID);
      const docSnap = await getDocFromServer(docRef);

      const stuff = {
        ...docSnap.data(),
        id: docSnap.id,
        exists: docSnap.exists(),
      };

      setAd(stuff);
      setServerAd(stuff);
    }

    doGet();
  }, [adID]);

  const handleTemporaryChange = useCallback((attribute, newVal) => {
    setAd((oldAd) => ({
      ...oldAd,
      [attribute]: newVal
    }))
  }, []);

  const handleTemporaryTargetingChange = useCallback((attribute, newVal) => {
    setAd((oldAd) => ({
      ...oldAd,
      targeting: {
        ...(oldAd.targeting || {}),
        [attribute]: newVal,
      }
    }))
  }, []);

  const changesMade = useMemo(() => isEqual(ad, serverAd), [ad, serverAd]);

  const saveChanges = useCallback(async () => {
    if (changesMade) {
      return null;
    }

    const docRef = doc(firestore, 'ads', adID);
    const updateAble = pickBy(ad, (key) => noUpdateAds.indexOf(key) < 0);

    await updateDoc(docRef, updateAble);

    setServerAd(ad);
  }, [adID, ad, changesMade]);

  return {ad, handleTemporaryChange, handleTemporaryTargetingChange, changesMade, saveChanges };
}