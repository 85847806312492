// import logo from './logo.svg';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import Home from './pages/home';
import LogIn from './pages/logIn';
import Reports from './pages/reports';
import Ads from './pages/ads';
import Ad from './pages/ad';
import Users from './pages/users';
import User from './pages/user';
import Layout from './components/Layout';
import { useUser } from './data/hooks';

// import Ads from './pages/ads';
// import Ads from './pages/ads';
// import Ads from './pages/ads';

function Report() {
  return <h2>Report</h2>;
}

function PrivateRoute({ children }) {
  const user = useUser();
  let location = useLocation();

  console.log('user is', user);

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function App() {

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index element={<Home />} />

        <Route path="login" element={<LogIn />} />

        <Route path="reports" element={<PrivateRoute><Reports /></PrivateRoute>}>
          <Route path=":reportID" element={<PrivateRoute><Report /></PrivateRoute>} />
        </Route>

        <Route path="users">
          <Route index element={<PrivateRoute><Users /></PrivateRoute>} />
          <Route path=":uid" element={<PrivateRoute><User /></PrivateRoute>} />
        </Route>

        <Route path="ads">
          <Route index element={<PrivateRoute><Ads /></PrivateRoute>} />
          <Route path=":adID" element={<PrivateRoute><Ad /></PrivateRoute>} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
